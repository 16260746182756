import type { PartialDeep } from 'type-fest';
import type {
  AddressSettings,
  DynamicBonusConfig,
  DynamicDispatchConfig,
  IAddress,
  ParentIdRef,
} from './address';
import type { IBaseItem, IDiscriminatedDocument } from './_base';
import type {
  IDispatchPrefConfig,
  IDispatchPrefTemplate,
} from './dispatchPref';
import type { IPoint, IStatusLog } from './_mixins';
import type { Timestamp, UUID } from './utils';
import type { IAssignment } from './assignment';
import type { ICompany } from './company';
import type { IPool } from './pool';
import type { IRole } from './role';
import type { ITag } from './cert';
import type { IUser, IWorker } from './user';
import type { SearchSpecifier } from './search';

export const issueSources = [
  'internalLiveMonitoring',
  'customerFrontInbox',
  'customerTextSMS',
  'customerDirectCall',
  'customerPersonalEmail',
  'partnerZendeskTicket',
] as const;
export type IssueSource = (typeof issueSources)[number];
export const issueCodes = [
  'billing',
  'checkInCheckOutIssues',
  'criminalBehavior',
  'inappropriateBehaviorTeachable',
  'inappropriateBehaviorUnacceptable',
  'injuryAccommodationRequest',
  'lateArrival',
  'noShow',
  'noWorkCompletion',
  'other',
  'partialWorkCompletion',
  'partnerTurnaway',
  'positiveFeedback',
  'scheduleRelatedRequests',
] as const;
export type IssueCode = (typeof issueCodes)[number];
export const subIssueCodes = [
  'planogramNotFollowed',
  'insufficientOutput',
  'backstockNotOrganized',
  'poorMerchandisingQuality',
  'competitorProductsMerchandised',
  'incompleteTLM',
  'productsDamaged',
  'productsCordwooding',
  'partnerLeftEarly',
  'lowQualityWork',
  'dressCodeViolation',
  'insufficientTraining',
  'appNotWorking',
  'storeNoCodeReceived',
  'wrongStoreVisit',
  'storeClosed',
  'equipmentNotWorking',
  'truckDeliveryMissed',
  'insufficientHoursBudget',
  'dressCode',
  'shiftDelayedByShiftsmart',
  'multiplePartnersSent',
  'other',
] as const;
export type SubIssueCode = (typeof subIssueCodes)[number];
export const actionCodes = [
  'callPartnerPickedUp',
  'callPartnerNoAnswer',
  'callStorePickedUp',
  'callStoreNoAnswer',
  'sendPartnerHome',
  'other',
] as const;
export type ActionCode = (typeof actionCodes)[number];

export const escalationEvents = ['create', 'resolve', 'update'] as const;
export type EscalationEvent = (typeof escalationEvents)[number];

type EscalationWithoutHistory = {
  actionCode?: ActionCode;
  actionSummary?: string;
  createdAt: Timestamp;
  /** Stores what type of action has been performed to an escalation */
  event?: EscalationEvent;
  isIssueValid?: boolean;
  issueCode: IssueCode;
  issueSource: IssueSource;
  issueSummary?: string;
  partnerId: IWorker['uuid'];
  resolvedAt?: Timestamp;
  userId: IUser['uuid'];
  uuid: UUID;
};

export type Escalation = EscalationWithoutHistory & {
  history?: EscalationWithoutHistory[];
};

export interface IThing
  extends IBaseItem,
    IDiscriminatedDocument<'shift' | 'survey' | 'block' | 'thing'> {
  // UUIDs of Accepted Users,
  activatedAt: Timestamp;

  /**
   * @deprecated Use `formattedAddress` instead
   */
  address?: string;
  /**
   * provides a reference to the item in the `addresses` collection.
   */
  addressRef: IAddress['uuid'];

  // UUIDs of Accepted Assignments,
  assignedUsers: UUID[];

  // ; default: 1,
  assignments: UUID[];

  /**
   * Any attribute that doesn't define the thing
   */
  attrs: Record<string, unknown> & {
    dispatchMode?: 'default' | 'dynamic';
  };

  autoLaunch: Timestamp;

  // Flat bonus amount for a shift/survey
  bonus: number;

  /** @deprecated A virtual field populated via mongoose from `__t */
  category: 'shift' | 'survey' | 'block' | 'thing' | undefined;

  certs: Array<ITag['uuid']>;

  /**
   * #### checkinCode
   *
   * Used to validate if partner was actually in the workplace or not
   * Employer only provide the checkin code to partners, who goes to the workplace for check in
   * For an individual company, a check-in code, consistent to all shift at a given location and day
   */
  checkinCode?: string;

  /**
   * #### checkoutCode
   *
   * Used to validate if partner was actually in the workplace or not
   * Employer only provide the checkout code to partners, who goes to the workplace for checkout
   * For an individual company, a check-in code, consistent to all shift at a given location and day
   */
  checkoutCode?: string;

  /** @deprecated ... TODO: move to IBLock schema only used for blocks */
  children: UUID[];

  // ; required: false },
  /**
   * Represents the Company who Created (and "owns") the "Thing"
   */
  company?: UUID;

  completedAt: Timestamp;

  // ; default: false
  currency: string;

  /**
   * Represents the "Location" or "Address" related to this thing
   * @deprecated use `addressRef` instead
   */
  customer?: UUID;

  description?: string;

  disableCheckinCheckoutCodeUpdates?: boolean;
  disablePartnerCancel: boolean;

  /**
   * ### duration
   * The total duration between the start and end of a shift, measured in Hours.
   *
   * @see IThing.payableDuration
   *
   * @type {string}
   * @memberof IThing
   */
  duration: string;

  /**
   * #### enableCheckinCode
   *
   * Determine whether this thing/shift will use the checkin code for the partners
   * When the company settings has `enableOnsiteCheckinCode` true, only then this can be played.
   * With "enableCheckinCode" and "enableOnsiteCheckinCode" being enabled, we will generate a checkin code
   * for each day for a shift
   */
  enableCheckinCode?: boolean;

  end: Timestamp;

  escalations?: Escalation[];

  excludeFromDashboardCalc: boolean;
  expiresAt: Timestamp;
  /** data sourced from 3rd party systems; eg - "item count" for Kroger */
  externalData?: Record<string, unknown>;

  externalId?: string;
  externalLink?: string;

  externalLinkButtonText: string;

  /**
   * String representation of the address, useful for quick display or search
   */
  formattedAddress?: string;

  // ; default: null
  isWindow: boolean;

  /**
   * @deprecated unused
   */
  locations?: any | any[];

  managerCancelReason?: string;

  manualUpdate: {
    updatedAt: Timestamp;
    updatedBy: UUID;
  };
  marketingCode?: string;
  matches: {
    pools: UUID[];
    users: UUID[];
  };
  /** Array of Event Definitions which control shift dispatch, relaunch and bonusing */
  notificationPrefs: Array<ShiftDispatchEventConfigRecord>;

  operatorId?: IUser['uuid'];

  /**
   * freeform configuration options, loosely defined but anything _can_ be
   * added here. There are no indexes on this field so please query with care.
   */
  options?: {
    /** @deprecated [2022-04-13] Only identified use is in deprecated NotificationPrefs ui store */
    dispatchType?: 'internal' | 'mixed' | 'external' | string | null;

    /**
     * ### inviteMode
     * Controls how partners will be invited to shifts as we transition away from assignments
     * based shift invites. @see {@link CompanySettings.dispatch.defaultInviteMode}
     */
    inviteMode?: unknown;
    // FIXME: below is what it was, but not sure what its suppose to be
    // inviteMode?: CompanySettings['dispatch']['defaultInviteMode'];
  };

  /**
   * UUIDs of parent Zones, used for querying by zone
   */
  parentAddressRefs: [
    ParentIdRef,
    ParentIdRef,
    ParentIdRef,
    ParentIdRef,
    ParentIdRef,
  ];

  /**
   * Allows establishment of hierarchy between different things.
   *
   * For example, a "Task" may have a "Survey" associated with it.
   *
   * The "Path" allows the child Surveys to reference their parent and
   * understand how deep in the hierarchy they are.
   *
   * The "Children" maintains a listing of "Child" Things that exist
   * one level below.
   *
   * Additionally, "all" children can be found by querying "things" for
   * path which starts with `this.path`. Eg: `thing.path = /^this.path\/.+/`
   */
  path: string;

  // ; default: null
  pay: number;

  // ; default: usd
  /**
   * ### payableDuration
   * The elapsed total duration, minus any unpaid breaks taken during the shift.
   *
   * Measured in hours.
   *
   * @see IThing.duration
   *
   * @type {number}
   * @memberof IThing
   */
  payableDuration: number;

  photoUploadRequired: boolean;

  /* Question Fields */
  question?: string;

  questionType?: 'text' | 'bool' | 'mc';

  rate: number;
  remindInterval?: number;
  /**
   * #### requireCheckinCode
   *
   * Checkin Codes will be required for all partners in order to check in for this shift.
   */
  requireCheckinCode?: boolean;

  /**
   * #### requireCheckinCode
   *
   * Checkin Codes will be required for all partners in order to check in for this shift.
   */
  requireCheckoutCode?: boolean;
  requirements?: unknown;
  // Reference to schedulingTemplate (if created by template)
  schedulingTemplate?: string;
  // Concatenation of foreign object fields as string for full text search
  // Set in the shifts after hook when assigning users
  searchableText?: string;
  /**
   * When shifts are bulk activated, notifications will be sent in bulk as well, 1 per user
   */
  sendActivationInBulk?: boolean;

  sendCheckinReminder: boolean;
  // Employer can indicate if during assignment, we should send SMS
  // This will likely change as the UI becomes more refined
  sendSMS: boolean;

  /** Specific options that have been configured for this shift */
  settings?: Record<string, null> &
    PartialDeep<Pick<AddressSettings, 'shiftDispatch'>>;

  /**
   * ### slots
   * The number of assignments required for this Thing
   *
   * Once the number of assignments matches the number of slots,
   * the status will be toggled to "Filled"
   */
  slots: number;

  source:
    | 'api-external'
    | 'auto-create-floaters'
    | 'auto-duplication'
    | 'csv-import'
    | 'portal';

  /**
   * ### sourceData
   * Contains the original data that was used to create the shift when
   * posted from an external source.
   */
  sourceData?: Record<string, unknown>;

  /* Shift Fields: */
  start: Timestamp;

  status: /* Shift has been created, but no notifications sent     */
  | 'Draft'
    /* Shift is "Live" but not yet processed ... */
    | 'Pending'
    /* Shift is "Live" and workers have been notified  */
    | 'Active'
    /* Shift has been Filled */
    | 'Filled'
    /* Shift has been canceled. */
    | 'Canceled'
    /* Shift has Expired without being fully Filled  */
    | 'Expired'
    /* Shift/Thing/Task has been 'deleted' */
    | 'Deleted'
    /* Shift/Thing/Task has been 'completed' */
    | 'Completed';

  statusLog: Array<IStatusLog<unknown>>;

  timezone?: string;

  title: string;
  uploadedAssets: {
    assetType: string;
    index: number;
    name: string;
    url: string;
  }[];

  /** This shift (or thing) has unique checkin codes that differ from the location/day pair's static code */
  useUniqueAttendanceCodes?: boolean;

  /**
   * Represents the User who Created the "Thing"
   */
  user?: UUID;
}

export type ShiftDispatchEventConfigRecord =
  | INotificationPref
  | IDynamicBonusPref
  | IDynamicNotificationPref;

export interface INotificationPref extends IDispatchPrefConfig {
  /** The date/time to be used in determining the schedule */
  baselineTime?: Timestamp;

  category?:
    | 'conflict'
    | 'timeOff'
    | 'oversubscribed'
    | 'retroactive'
    | 'restricted'
    | `retroactive.${IPool['uuid']}`
    | 'dynamicRelaunch'
    | 'dynamicBonus'
    | 'floaterPromotion'
    | 'workFrequency'
    | 'dispatchScoring';

  /** Time that the notificationPref was added to shift */
  createdAt?: Timestamp;
  /** enable to enforce NP level search restrictions {@see `INotificationPref['restrictions']} */
  enforceRestriction?: boolean;
  /** If the pref group fails to send, the error will be stored here */
  errorMsg?: string;

  hasProcessedWaitlistUsers?: boolean;

  /** Allow override of restrictions during automated dispatch */
  ignoreRestrictions?: boolean;

  ignoredUsers?: Array<{
    message: string;
    status: IAssignment['status'] | string;
    userId: IUser['uuid'];
  }>;

  /** Used to disable or cancel a scheduled notification */
  isDeleted?: boolean;
  /** 🤔 */
  note?: string;

  /**
   * ### processedIndex
   * @description
   * Variable used to track progress in processing the users defined in the NP
   * Used primarily for `dynamicRelaunch` NPs
   */
  processedIndex?: number;

  /** searchSpecifier, @deprecated */
  restrictions?: SearchSpecifier;

  /**
   * Timestamp updated once the full pref has been processed
   *
   * TODO: Is this set at process start or finish?
   */
  sentAt?: null | Timestamp;

  /**
   * Represents the current processing status of the notification pref level. In order,
   * the options represent the following statuses:
   *
   * - `draft | nil`: The pref has not yet been saved to the database
   * - `created`: The pref has been saved to the DB, but not yet processed
   * - `processing`: The pref is actively being processed by the `processShiftInvites` job
   * - `sent`: Invites have been sent to all targeted users for this pref level
   * - `empty`: No partners were found that matched this pref level's matching criteria
   * - `error`: Something went wrong, {@see INotificationPref['errorMsg']}
   * - `canceled`: The corresponding job was canceled before it could be run
   */
  status:
    | 'draft'
    | 'created'
    | 'processing'
    | 'sent'
    | 'empty'
    | 'error'
    | 'canceled';
  /** The uuid of the source Dispatch Pref Template */
  templateId?: IDispatchPrefTemplate['uuid'];

  waitlistUserIDs?: IUser['uuid'][];
}

export type IDynamicNotificationPref = INotificationPref & {
  category: 'dynamicRelaunch';

  /** Timestamp when the "Priority" for the dynamic user list expires and the normal dispatch process resumes */
  expiresAt?: Timestamp;

  settings?: DynamicDispatchConfig;
};

export type IDynamicBonusPref = INotificationPref & {
  category: 'dynamicBonus';

  /** Whether or not the bonus is currently "active" */
  isActive?: boolean;

  /** The pools which are eligible for this bonus */
  poolIds: Array<IPool['uuid']>;

  settings?: DynamicBonusConfig;

  /** The specific bonus definition */
  slug: string;
};

export interface IJoinedPosition {
  position?: IRole;
}

export interface IJoinedCompanies {
  companyObj?: ICompany;
}

/**
 * ### IJoinedAddress
 * Represents the fields included in a response object when the `addrResolvers`
 * hooks are executed.
 *
 * @export
 * @interface IJoinedAddress
 */
export interface IJoinedAddress<T extends IAddress = IAddress> {
  readonly addressObj: T;
  readonly formattedAddress: string;
  readonly loc: IPoint;
}
