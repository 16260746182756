import { IBaseItem } from './_base';

// These are the reason codes not shown in the mobile TLM UI
// Either deprecated, or DM tool specific (like partner issue)
export enum OtherReasonCode {
  EXTRA_PRODUCT_IN_BIN = 'Extra product in bin',
  IN_STORE_SIGNAGE = 'In-store signage',
  MANAGER_DIRECTION = 'Manager direction',
  MANAGER_REQUEST = 'Manager request',
  NOT_FOUND_IN_BIN = 'Not found in bin',
  NO_PHOTOS = 'No Photos',
  PARTNER_ISSUE = 'Partner issue',
  PRODUCED = 'Produced',
  PRODUCT_DAMAGED = 'Product damaged',
  PRODUCT_DAMAGED_OR_EXPIRED = 'Product damaged or expired',
  PRODUCT_EXPIRED = 'Product expired',
  PRODUCT_NOT_THAWED = 'Product not thawed',
  PRODUCT_NOT_USABLE = 'Product not usable',
  PRODUCT_SUBSTITUTION = 'Product substitution',
  RAN_OUT_OF_TIME = 'Ran out of time',
  SANDWICH_NOT_THAWED = 'Sandwich not thawed',
  SHIFT_TURNAWAY = 'Shift turnaway',
  STORE_ALREADY_COOKED = 'Store already cooked',
  STORE_ALREADY_PRODUCED = 'Store already produced',
  UNFILLED_SHIFT = 'Unfilled shift',
}

export const IssueTypeValues = {
  CANT_SCAN: "Can't Scan",
  CANT_USE: "Can't use",
  CUSTOMER_REQUEST: 'Customer request',
  EQUIPMENT_ISSUE: 'Equipment issue',
  FOUND_IN_BIN: 'Found in Bin',
  NOT_IN_BIN: 'Not in Bin',
  NOT_IN_POG: 'Not in POG',
  OTHER: 'Other',
  OUT_OF_STOCK: 'Out of stock',
  SHIFT_ISSUE: 'Shift Issue',
  STOCKED_UNUSABLE: 'Stocked - Unusable',
  STORE_REQUEST: 'Store request',
  SUBSTITUTION: 'Substitution',
  ...OtherReasonCode,
} as const;

export type IssueType = (typeof IssueTypeValues)[keyof typeof IssueTypeValues];

export enum SubReasonCode {
  APP_SCANNER_ISSUE = 'App scanner issue',
  COULDNT_FIND_ITEM = "Couldn't find item",
  HOT_CASE = 'Hot case',
  IPAD_OR_LABEL_PRINTER = 'iPad or label printer',
  ITEM_WAS_DAMAGED_OR_DROPPED = 'Item was damaged or dropped',
  ITEM_WAS_EXPIRED = 'Item was expired',
  ITEM_WAS_NOT_THAWED = 'Item was not thawed',
  I_ASKED_THE_STORE_WHAT_TO_COOK = 'I asked the store what to cook',
  I_ASKED_THE_STORE_WHAT_TO_PREP = 'I asked the store what to prep',
  I_ASSUMED_STORE_WANTED_LESS = 'I assumed the store wanted less',
  I_ASSUMED_STORE_WANTED_MORE = 'I assumed the store wanted more',
  I_WANTED_TO_SUBSTITUTE = 'I wanted to substitute',
  MANAGER_OR_EMPLOYEE_ASKED_TO_COOK_LESS = 'Manager or employee asked to cook less',
  MANAGER_OR_EMPLOYEE_ASKED_TO_COOK_MORE = 'Manager or employee asked to cook more',
  MANAGER_OR_EMPLOYEE_ASKED_TO_PREP_LESS = 'Manager or employee asked to prep less',
  MANAGER_OR_EMPLOYEE_ASKED_TO_PREP_MORE = 'Manager or employee asked to prep more',
  MANAGER_WANTED_TO_SUBSTITUTE = 'Manager wanted to substitute',
  MISSING_BARCODE = 'Missing barcode',
  OVENS = 'Ovens',
  RAN_OUT_OF_TIME = 'Ran out of time',
  ROLLER_GRILLS = 'Roller Grill(s)',
  STORE_ALREADY_COOKED_ITEM = 'Store already cooked item',
  STORE_SIGN_REQUESTED_DIFFERENT_ITEMS = 'Store sign requested different items',
  UNSURE_HOW_TO_COOK = 'Unsure how to cook',
  UNSURE_HOW_TO_PREP = 'Unsure how to prep',
}

export type SubIssueType = `${SubReasonCode}`;

interface SubReason {
  issue: string;
  subReasonCodeOrder: number;
}

interface IssueReason {
  question?: string;
  reasonCodeOrder: number;
  subIssueType: SubReason[];
}

export type IssueReasonMap<T extends Record<string, string>> = Record<
  T[keyof T],
  IssueReason
>;

export interface ISkuMetadata {
  label?: string;
  sku_upc?: string;
}

export interface ISku extends IBaseItem {
  code?: string;
  companyId: string;
  group: string;
  imageURL?: string;
  labelURL?: string;
  metaData: ISkuMetadata;
  name: string;
  readableName?: string;
}

export interface IStoreSkuReport {
  report: IStoreSkuReportDetails;
  shiftId: string;
  skuId: ISku['uuid'];
  storeId: string;
  storeName: string;
  taskIdentifier?: string;
  userId: string;
  uuid: string;
}

export interface IStoreSkuReportDetails {
  count: number;
  issueType?: IssueType;
  method?: string;
  scanTimestamps?: Date[];
  subIssueType?: string;
  tlmTaskConfigId?: string;
  trayNumber?: number;
}
